import { isUndefined, isNumber } from "lodash";

/* Adapted from https://github.com/angular/angular.js/blob/eaa1119d4252bed08dfa42f984ef9502d0f02775/src/Angular.js#L1258 */
export function angularToJson(string, pretty) {
  let obj;

  try {
    obj = JSON.parse(string);
  } catch {
    // Not valid JSON? Ain't nothing we can do
    return string;
  }

  if (isUndefined(obj)) return undefined;
  if (!isNumber(pretty)) {
    pretty = pretty ? 2 : null;
  }
  return JSON.stringify(obj, toJsonReplacer, pretty);
}

/* Copied from https://github.com/angular/angular.js/blob/eaa1119d4252bed08dfa42f984ef9502d0f02775/src/Angular.js#L1205 */
function toJsonReplacer(key, value) {
  var val = value;

  if (
    typeof key === "string" &&
    key.charAt(0) === "$" &&
    key.charAt(1) === "$"
  ) {
    val = undefined;
  } else if (isWindow(value)) {
    val = "$WINDOW";
  } else if (value && window.document === value) {
    val = "$DOCUMENT";
  } else if (isScope(value)) {
    val = "$SCOPE";
  }

  return val;
}

/**
 * Checks if `obj` is a window object.
 *
 * @private
 * @param {*} obj Object to check
 * @returns {boolean} True if `obj` is a window obj.
 */
function isWindow(obj) {
  return obj && obj.window === obj;
}

function isScope(obj) {
  return obj && obj.$evalAsync && obj.$watch;
}
