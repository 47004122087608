import {
  QueryClient,
  QueryCache,
  MutationCache,
  useQueryClient as rqUseQueryClient,
  useQuery as rqUseQuery,
  useInfiniteQuery as rqUseInfiniteQuery,
  queryOptions,
  useMutation as rqUseMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
import { setupHandleError } from "error-logging!sofe";
type ErrorOptions = Record<string, unknown>;

export { queryOptions };

const queryCache = new QueryCache({
  onError: (err, query) => {
    const baseKey = query.queryKey[0];

    const serviceName =
      baseKey &&
      typeof baseKey === "object" &&
      "service" in baseKey &&
      typeof baseKey.service === "string"
        ? baseKey.service
        : "unknown service";

    const errorOptions: ErrorOptions | undefined =
      typeof query.options.meta?.errorOptions === "function"
        ? query.options.meta.errorOptions(err)
        : query.options.meta?.errorOptions;

    const handleServiceError = setupHandleError(serviceName);
    handleServiceError(err, errorOptions);
  },
});

const mutationCache = new MutationCache({
  onError: (err, _variables, _context, mutation) => {
    if (!mutation.options.onError) {
      const handleServiceError = setupHandleError("unknown service");
      handleServiceError(err);
    }
  },
});

export const queryClient = new QueryClient({
  queryCache: queryCache,
  mutationCache: mutationCache,
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: 15000, // 15 seconds
    },
  },
});

export function useQueryClient() {
  return rqUseQueryClient(queryClient);
}

export function useQuery<
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData
>(queryOptions: UseQueryOptions<TData, TError, TQueryFnData>) {
  if (queryOptions.staleTime === undefined) {
    throw new Error("staleTime is required for query " + queryOptions.queryKey);
  }
  return rqUseQuery<TData, TError, TQueryFnData>(queryOptions, queryClient);
}

export function useInfiniteQuery<
  TData = unknown,
  TError = unknown,
  TQueryFnData = TData
>(queryOptions: UseInfiniteQueryOptions<TData, TError, TQueryFnData>) {
  if (queryOptions.staleTime === undefined) {
    throw new Error("staleTime is required for query " + queryOptions.queryKey);
  }
  return rqUseInfiniteQuery<TData, TError, TQueryFnData>(
    queryOptions,
    queryClient
  );
}

export function useMutation<
  TData = unknown,
  TError = Error,
  TVariables = void,
  TContext = unknown
>(mutationOptions: UseMutationOptions<TData, TError, TVariables, TContext>) {
  return rqUseMutation<TData, TError, TVariables, TContext>(
    mutationOptions,
    queryClient
  );
}

type BaseQueryKeyObj = { service: string; entity: string };

/**
 * Removes all undefined values from an object and its nested objects.
 * We want to use this for our query params because of https://github.com/TanStack/query/issues/3741
 * @param obj - The object to remove undefined values from.
 * @returns A new object with all undefined values removed.
 */
export function removeUndefinedObjKeys<T extends object>(obj: T): T {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  const result: Record<string, unknown> = {};

  /**
   * Stack-based approach to iteratively traverse the object.
   * Each item in the stack is a pair:
   * - `current`: The current object being processed.
   * - `output`: The corresponding object where filtered values are stored.
   */
  const stack: Array<[Record<string, unknown>, Record<string, unknown>]> = [
    [obj as Record<string, unknown>, result],
  ];

  while (stack.length > 0) {
    // Take the last added pair from the stack (LIFO: Last-In-First-Out)
    const [current, output] = stack.pop()!;

    for (const [key, value] of Object.entries(current)) {
      if (value === undefined) continue;
      if (value && typeof value === "object" && !Array.isArray(value)) {
        /**
         * If the value is a non-null object (excluding arrays),
         * create a new object in the output and push both onto the stack.
         */
        const newObj: Record<string, unknown> = {};
        output[key] = newObj;
        stack.push([value as Record<string, unknown>, newObj]);
      } else {
        // If the value is not an object (or is an array or primitive value), simply assign it to the output.
        output[key] = value;
      }
    }
  }

  return result as T;
}

export function setupQueryKeygen(serviceName: string) {
  /**
   * Creates the base key to be used for every query.
   * @param entityName - The name of the entity. This name will be used to invalidate all queries that share this entity name.
   */
  const genBaseKey = (entityName: string): [BaseQueryKeyObj] => {
    return [
      {
        service: serviceName,
        entity: entityName,
      },
    ];
  };

  /**
   * Generates a 'standalone' query key to be used for any one off queries.
   *
   * @param entityName - The name of the query.
   * @param queryParams - The parameters for the query. This should contain all the parameters that are used in your queryFn.
   * @returns The generated query key. First item being the base key, the second being entity name, and the third item being the query parameters.
   */
  const genQueryKey = <TQueryParams extends object>(
    entityName: string,
    queryParams: TQueryParams = {} as TQueryParams
  ): [BaseQueryKeyObj, string, TQueryParams] => {
    return [
      genBaseKey(entityName)[0],
      entityName,
      removeUndefinedObjKeys(queryParams),
    ];
  };

  /**
   * Invalidates the queries associated with the specified entity.
   * @param entityName - The name of the entity.
   */
  const invalidateEntity = (entityName: string) => {
    return queryClient.invalidateQueries({
      queryKey: [{ entity: entityName }],
    });
  };

  /**
   * Generates an object from the one provided in the callback, and adds a utility method to generate query keys as well as providing a default invalidation method.
   *
   * @param entityName - The name of the entity, this will be used to group all queries that share the same resource. e.g. contacts, clients, tasks would be entity names.
   * @param getQueriesObject - A function that should return an object with your queryOptions or anything else you want to return. The callback will provide a `genKey` method for generating the keys.
   * @returns Whatever you return from the `getQueriesObject` callback, with the addition of an `invalidate` method that will invalidate all queries that share the same entity name.
   */
  const genQueries = <TQueriesObject>(
    entityName: string,
    getQueriesObject: (params: {
      genKey: <TQueryParams extends object>(
        queryName: string,
        queryParams?: TQueryParams
      ) => [BaseQueryKeyObj, string, TQueryParams];
    }) => TQueriesObject
  ) => {
    const baseKey = genBaseKey(entityName);

    /**
     * Generates a composite query key that includes the base key, query name, and query parameters.
     * @param queryName - A unique name for this query. Only needs to be unique per entity.
     * @param queryParams - The parameters for the query. This should contain all the parameters that are used in your queryFn.
     * @returns A query key [baseKey, queryName, queryParams]
     */
    const genKey = <TQueryParams extends object>(
      queryName: string,
      queryParams: TQueryParams = {} as TQueryParams
    ): [BaseQueryKeyObj, string, TQueryParams] => {
      return [baseKey[0], queryName, removeUndefinedObjKeys(queryParams)];
    };

    return {
      baseKey,
      genKey,
      invalidate: () => invalidateEntity(entityName),
      ...getQueriesObject({ genKey }),
    };
  };

  return {
    genBaseKey,
    genQueryKey,
    genQueries,
    invalidateEntity,
  };
}
